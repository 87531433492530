const DeleteMaterial = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665"
        stroke={props.stroke || "#8283BE"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.66602 3.3135L5.81268 2.44016C5.91935 1.80683 5.99935 1.3335 7.12602 1.3335H8.87268C9.99935 1.3335 10.086 1.8335 10.186 2.44683L10.3327 3.3135"
        stroke={props.stroke || "#8283BE"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5669 6.09326L12.1336 12.8066C12.0603 13.8533 12.0003 14.6666 10.1403 14.6666H5.86026C4.00026 14.6666 3.94026 13.8533 3.86693 12.8066L3.43359 6.09326"
        stroke={props.stroke || "#8283BE"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.88672 11H9.10672"
        stroke={props.stroke || "#8283BE"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.33398 8.3335H9.66732"
        stroke={props.stroke || "#8283BE"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { DeleteMaterial };
