export enum EMaterialsId {
  ADDITIONS = 1,
  APPLICATIONS = 2,
  CANVAS_FRAMES = 3,
  COLORS = 4,
  DOUBLE_SIDED_TAPE_ROLLS = 5,
  ENVELOPES = 6,
  FOILS = 7,
  FRAMES = 8,
  GLUES = 9,
  HARDBOARDS = 10,
  LAMINATIONS = 11,
  MAGNETS = 12,
  MATERIAL_ROLL_PRINTINGS = 13,
  PACKIN_DRUMS = 14,
  PACKINGS = 15,
  PACKIN_UNITS = 16,
  PLATS = 17,
  PROFILE_FRAMES = 18,
  ROLL_ENCAPSULATIONS = 19,
  SHEET_ENCAPSULATIONS = 20,
  SHEETS = 21,
  TUBES = 22,
  VARNISHS = 23,
  WIDE_FORMAT_MATERIAL = 24,
  DIE_STAMP = 25,
}
