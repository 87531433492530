const TickIcon = (props: any) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.98248 11.0249L8.10748 12.1499L11.1075 9.1499" stroke="#8283BE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.5 4.5H10.5C12 4.5 12 3.75 12 3C12 1.5 11.25 1.5 10.5 1.5H7.5C6.75 1.5 6 1.5 6 3C6 4.5 6.75 4.5 7.5 4.5Z" stroke="#8283BE" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 3.01489C14.4975 3.14989 15.75 4.07239 15.75 7.49989V11.9999C15.75 14.9999 15 16.4999 11.25 16.4999H6.75C3 16.4999 2.25 14.9999 2.25 11.9999V7.49989C2.25 4.07989 3.5025 3.14989 6 3.01489" stroke="#8283BE" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export { TickIcon };
