const UploadMaterialsPictures = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33398 1.33339H6.00065C2.66732 1.33339 1.33398 2.66672 1.33398 6.00006V10.0001C1.33398 13.3334 2.66732 14.6667 6.00065 14.6667"
        stroke="#8283BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.668 6.66672V8.66672"
        stroke="#8283BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6673 6.66672H12.0007C10.0007 6.66672 9.33398 6.00006 9.33398 4.00006V1.33339L14.6673 6.66672Z"
        stroke="#8283BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 18.6319C14.2091 18.6319 16 16.841 16 14.6319C16 12.4228 14.2091 10.6319 12 10.6319C9.79086 10.6319 8 12.4228 8 14.6319C8 16.841 9.79086 18.6319 12 18.6319Z"
        fill="#8283BE"
      />
      <path
        d="M13.2601 16.452V14.8868H14.3476C14.3785 14.8846 14.408 14.8756 14.4327 14.8607C14.4574 14.8459 14.4763 14.8259 14.4873 14.803C14.4983 14.78 14.5009 14.7551 14.4948 14.7311C14.4888 14.7071 14.4742 14.6849 14.4529 14.6671L12.0898 12.6118C12.0767 12.5999 12.0605 12.5903 12.0423 12.5838C12.0242 12.5772 12.0044 12.5738 11.9844 12.5738C11.9644 12.5738 11.9447 12.5772 11.9265 12.5838C11.9083 12.5903 11.8921 12.5999 11.879 12.6118L9.54222 14.6463C9.52125 14.6644 9.50703 14.6868 9.50112 14.7109C9.49521 14.7351 9.49785 14.76 9.50874 14.783C9.51963 14.8061 9.53835 14.8263 9.56285 14.8414C9.58734 14.8566 9.61666 14.8662 9.64758 14.869H10.7765V16.4431C10.7755 16.4595 10.7786 16.4759 10.7856 16.4913C10.7926 16.5068 10.8034 16.521 10.8174 16.5331C10.8314 16.5452 10.8483 16.5551 10.8671 16.5621C10.8859 16.569 10.9062 16.573 10.927 16.5738H13.1284C13.1649 16.5689 13.1982 16.554 13.2222 16.5318C13.2463 16.5095 13.2597 16.4813 13.2601 16.452Z"
        fill="white"
      />
    </svg>
  );
};

export { UploadMaterialsPictures };
