const UnitsPriceMaterial = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.61151 15.0016H9.04237V13.6892C8.31066 13.7157 7.58583 13.5398 6.94771 13.1807V12.3202C7.09018 12.428 7.2428 12.5217 7.40342 12.5999C7.57301 12.685 7.7489 12.7569 7.92953 12.815C8.11287 12.8749 8.30052 12.9206 8.49084 12.9519C8.6731 12.9826 8.85756 12.9983 9.04237 12.9989V9.53709L8.28548 9.10877C8.04124 8.97102 7.8141 8.80493 7.60877 8.61395C7.40941 8.42568 7.24459 8.20393 7.12178 7.95876C6.98678 7.67633 6.9198 7.3662 6.9262 7.05323C6.92372 6.76099 6.97682 6.47095 7.08266 6.19854C7.18322 5.94239 7.33119 5.7075 7.51881 5.50619C7.71068 5.30343 7.93719 5.13653 8.18769 5.01333C8.45438 4.88184 8.74021 4.79339 9.03455 4.75125V3.62276H9.60369V4.73365C9.92652 4.73657 10.2482 4.77196 10.564 4.83926C10.8083 4.89226 11.0453 4.97434 11.27 5.08374V5.90126C10.7725 5.59304 10.1967 5.43482 9.61151 5.44556V8.98164C9.96609 9.15247 10.3079 9.34855 10.6344 9.56838C10.8777 9.73264 11.0998 9.92644 11.2955 10.1453C11.4524 10.3238 11.5733 10.5311 11.6514 10.7556C11.7238 10.9751 11.7594 11.205 11.757 11.4362C11.7595 11.7129 11.7098 11.9876 11.6103 12.2459C11.5139 12.4912 11.3711 12.7156 11.1898 12.9069C10.996 13.1074 10.7674 13.2711 10.5151 13.39C10.2295 13.5253 9.9247 13.6157 9.61151 13.658V15.0016ZM9.04237 5.46316C8.68097 5.51912 8.34965 5.69721 8.10359 5.96776C7.87051 6.23928 7.74707 6.58797 7.75741 6.94566C7.75633 7.11611 7.77871 7.2859 7.82391 7.45025C7.86239 7.60136 7.92872 7.74397 8.01949 7.87075C8.13123 8.01854 8.26286 8.15017 8.41065 8.26191C8.60999 8.40658 8.81911 8.53728 9.03651 8.65307L9.04237 5.46316ZM9.61151 12.9676C9.98786 12.9175 10.3368 12.7433 10.6031 12.4727C10.8262 12.2134 10.9441 11.8798 10.9336 11.5379C10.935 11.3785 10.9106 11.22 10.8613 11.0685C10.8102 10.9164 10.7335 10.7742 10.6344 10.648C10.5155 10.5006 10.3781 10.3691 10.2256 10.2568C10.029 10.1142 9.82392 9.98354 9.61151 9.86566V12.9676Z"
        fill="#8283BE"
      />
      <path
        d="M9.29612 0.016266C11.1175 -0.094399 12.9227 0.414296 14.4184 1.45964C16.4915 2.86586 17.8977 4.79428 18.4219 7.30161C18.5583 7.8395 18.6195 8.3937 18.6038 8.9484C18.5862 9.20461 18.68 9.27893 18.9187 9.25546C19.1142 9.23785 19.2961 9.26524 19.4839 9.25546C19.9161 9.21634 19.9298 9.48037 19.7694 9.73071C19.2531 10.5385 18.7055 11.3227 18.1598 12.1109C17.9642 12.3945 17.7686 12.2948 17.6044 12.064C17.0958 11.309 16.5893 10.5502 16.0925 9.78743C15.8656 9.4393 15.9556 9.27501 16.3507 9.25937C17.266 9.22221 17.2151 9.22808 17.1174 8.28147C16.9747 6.88141 16.4269 5.55333 15.541 4.45984C14.3949 3.03797 12.932 2.019 11.1033 1.60829C9.37703 1.18357 7.55615 1.38381 5.96344 2.17351C3.56955 3.37046 2.04989 5.27932 1.58441 7.94703C1.37903 9.0036 1.40099 10.0918 1.64885 11.1392C1.89672 12.1866 2.36478 13.1693 3.02192 14.0217C3.61817 14.8372 4.36936 15.5271 5.23252 16.052C6.09568 16.5769 7.05386 16.9264 8.05223 17.0806C10.319 17.4561 12.355 16.885 14.1719 15.516C14.6839 15.0952 15.1524 14.6241 15.5703 14.1097C15.8774 13.7655 16.2431 13.656 16.5991 13.8927C16.955 14.1293 17.0137 14.5302 16.7399 14.939C16.1305 15.8098 15.3556 16.5522 14.4594 17.1236C13.2627 17.9376 11.8807 18.438 10.4403 18.5787C8.06788 18.7958 5.84023 18.3343 3.87661 16.8889C1.90595 15.4757 0.572656 13.3419 0.166457 10.9511C-0.334227 8.25604 0.309231 5.80934 1.89929 3.64232C3.21555 1.84494 5.03248 0.718398 7.20537 0.194244C7.889 0.0278216 8.5942 -0.0322094 9.29612 0.016266Z"
        fill="#8283BE"
      />
    </svg>
  );
};

export { UnitsPriceMaterial };
