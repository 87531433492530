const SettingIcon = ({ ...props }) => {
  return (
    <svg
      width={props.width || "24"}
      height={props.height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9136 15C14.5704 15 15.9136 13.6569 15.9136 12C15.9136 10.3431 14.5704 9 12.9136 9C11.2567 9 9.91357 10.3431 9.91357 12C9.91357 13.6569 11.2567 15 12.9136 15Z"
        stroke={props.stroke || "#ED028C"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.91357 12.8799V11.1199C2.91357 10.0799 3.76357 9.21994 4.81357 9.21994C6.62357 9.21994 7.36357 7.93994 6.45357 6.36994C5.93357 5.46994 6.24357 4.29994 7.15357 3.77994L8.88357 2.78994C9.67357 2.31994 10.6936 2.59994 11.1636 3.38994L11.2736 3.57994C12.1736 5.14994 13.6536 5.14994 14.5636 3.57994L14.6736 3.38994C15.1436 2.59994 16.1636 2.31994 16.9536 2.78994L18.6836 3.77994C19.5936 4.29994 19.9036 5.46994 19.3836 6.36994C18.4736 7.93994 19.2136 9.21994 21.0236 9.21994C22.0636 9.21994 22.9236 10.0699 22.9236 11.1199V12.8799C22.9236 13.9199 22.0736 14.7799 21.0236 14.7799C19.2136 14.7799 18.4736 16.0599 19.3836 17.6299C19.9036 18.5399 19.5936 19.6999 18.6836 20.2199L16.9536 21.2099C16.1636 21.6799 15.1436 21.3999 14.6736 20.6099L14.5636 20.4199C13.6636 18.8499 12.1836 18.8499 11.2736 20.4199L11.1636 20.6099C10.6936 21.3999 9.67357 21.6799 8.88357 21.2099L7.15357 20.2199C6.24357 19.6999 5.93357 18.5299 6.45357 17.6299C7.36357 16.0599 6.62357 14.7799 4.81357 14.7799C3.76357 14.7799 2.91357 13.9199 2.91357 12.8799Z"
        stroke={props.stroke || "#ED028C"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { SettingIcon };
