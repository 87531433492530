const MessageLogsIcon = (props: any) => {
    return (
        <svg width={props.width || "14"} height={props.height || "14"} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="12" height="12" rx="4" fill="#4B85F5" />
            <path d="M7 9.5C7 9.63261 6.94732 9.75979 6.85355 9.85355C6.75979 9.94732 6.63261 10 6.5 10C6.23478 10 5.98043 9.89464 5.79289 9.70711C5.60536 9.51957 5.5 9.26522 5.5 9V6.5C5.36739 6.5 5.24022 6.44732 5.14645 6.35355C5.05268 6.25979 5 6.13261 5 6C5 5.86739 5.05268 5.74022 5.14645 5.64645C5.24022 5.55268 5.36739 5.5 5.5 5.5C5.76522 5.5 6.01957 5.60536 6.20711 5.79289C6.39464 5.98043 6.5 6.23478 6.5 6.5V9C6.63261 9 6.75979 9.05268 6.85355 9.14645C6.94732 9.24021 7 9.36739 7 9.5ZM5 3.75C5 3.60166 5.04399 3.45666 5.1264 3.33332C5.20881 3.20999 5.32594 3.11386 5.46299 3.05709C5.60003 3.00033 5.75083 2.98547 5.89632 3.01441C6.0418 3.04335 6.17544 3.11478 6.28033 3.21967C6.38522 3.32456 6.45665 3.4582 6.48559 3.60368C6.51453 3.74917 6.49968 3.89997 6.44291 4.03701C6.38614 4.17406 6.29001 4.29119 6.16668 4.3736C6.04334 4.45601 5.89834 4.5 5.75 4.5C5.55109 4.5 5.36032 4.42098 5.21967 4.28033C5.07902 4.13968 5 3.94891 5 3.75Z" fill="white" />
        </svg>

    );
};

export { MessageLogsIcon };