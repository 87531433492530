const NewLogo = (props: any) => {
  return (
    <svg width="55" height="51" viewBox="0 0 55 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.2525 30.0849C13.1236 30.0849 11.1348 29.7067 9.28598 28.9504C7.43718 28.1661 5.81249 27.0876 4.41189 25.715C3.01129 24.3424 1.91882 22.7457 1.13449 20.925C0.378162 19.0762 0 17.0873 0 14.9584C0 12.8575 0.378162 10.8967 1.13449 9.07589C1.91882 7.25511 2.99728 5.67243 4.36987 4.32785C5.77047 2.95527 7.39517 1.89081 9.24396 1.13449C11.0928 0.378162 13.0816 0 15.2105 0C16.6952 0 18.1378 0.21009 19.5384 0.630269C20.967 1.05045 22.2555 1.6387 23.404 2.39502C24.5525 3.15135 25.4909 4.01972 26.2192 5.00014L23.278 8.06746C22.4376 7.25511 21.5692 6.58282 20.6729 6.05059C19.8045 5.51836 18.9081 5.11219 17.9837 4.83207C17.0593 4.55195 16.1349 4.41189 15.2105 4.41189C13.6979 4.41189 12.2973 4.678 11.0087 5.21023C9.74817 5.74246 8.6277 6.48478 7.64727 7.43718C6.69487 8.38959 5.95255 9.51007 5.42032 10.7986C4.88809 12.0592 4.62198 13.4458 4.62198 14.9584C4.62198 16.415 4.9021 17.8016 5.46234 19.1182C6.02258 20.4067 6.79291 21.5412 7.77333 22.5216C8.78176 23.5021 9.93025 24.2724 11.2188 24.8326C12.5354 25.3929 13.922 25.673 15.3786 25.673C16.4711 25.673 17.5075 25.5189 18.4879 25.2108C19.4683 24.8747 20.3367 24.4125 21.093 23.8242C21.8494 23.2359 22.4376 22.5637 22.8578 21.8073C23.278 21.023 23.4881 20.1966 23.4881 19.3283V18.4879L24.1183 19.0762H15.1265V14.5802H28.1521C28.2081 14.8884 28.2501 15.1965 28.2781 15.5046C28.3061 15.7848 28.3201 16.0649 28.3201 16.345C28.3481 16.5971 28.3621 16.8492 28.3621 17.1013C28.3621 19.0341 28.026 20.7989 27.3537 22.3956C26.6814 23.9643 25.743 25.3228 24.5385 26.4713C23.362 27.6198 21.9754 28.5162 20.3787 29.1605C18.8101 29.7767 17.1013 30.0849 15.2525 30.0849Z" fill="#2E3092" />
      <path d="M43.0947 30.0849C40.9378 30.0849 39.005 29.5947 37.2962 28.6143C35.6155 27.6058 34.2849 26.2472 33.3045 24.5385C32.3241 22.8018 31.8339 20.8269 31.8339 18.614C31.8339 16.401 32.3241 14.4402 33.3045 12.7315C34.2849 10.9947 35.6155 9.63613 37.2962 8.65571C39.005 7.64727 40.9378 7.14306 43.0947 7.14306C45.2236 7.14306 47.1284 7.64727 48.8092 8.65571C50.5179 9.63613 51.8625 10.9947 52.8429 12.7315C53.8233 14.4402 54.3135 16.401 54.3135 18.614C54.3135 20.8269 53.8233 22.8018 52.8429 24.5385C51.8625 26.2472 50.5179 27.6058 48.8092 28.6143C47.1284 29.5947 45.2236 30.0849 43.0947 30.0849ZM43.0947 26.1352C44.4113 26.1352 45.5878 25.8131 46.6242 25.1688C47.6607 24.4965 48.473 23.6001 49.0613 22.4796C49.6495 21.3311 49.9296 20.0426 49.9016 18.614C49.9296 17.1573 49.6495 15.8688 49.0613 14.7483C48.473 13.5998 47.6607 12.7034 46.6242 12.0592C45.5878 11.4149 44.4113 11.0927 43.0947 11.0927C41.7781 11.0927 40.5876 11.4289 39.5232 12.1012C38.4867 12.7455 37.6744 13.6418 37.0861 14.7903C36.4979 15.9108 36.2178 17.1854 36.2458 18.614C36.2178 20.0426 36.4979 21.3311 37.0861 22.4796C37.6744 23.6001 38.4867 24.4965 39.5232 25.1688C40.5876 25.8131 41.7781 26.1352 43.0947 26.1352Z" fill="#2E3092" />
      <path d="M1.15208 50.7759V35.0892H3.5275L9.24195 44.3892L8.09906 44.3668L13.8807 35.0892H16.1217V50.7759H13.7015V44.3444C13.7015 42.9998 13.7313 41.7897 13.7911 40.714C13.8658 39.6383 13.9853 38.5701 14.1496 37.5094L14.4634 38.3386L9.33158 46.2716H7.85255L2.87762 38.4282L3.12413 37.5094C3.28846 38.5104 3.40051 39.5412 3.46027 40.602C3.53497 41.6477 3.57232 42.8952 3.57232 44.3444V50.7759H1.15208Z" fill="#2E3092" />
      <path d="M23.5675 51C22.5964 51 21.7075 50.7311 20.9007 50.1933C20.1089 49.6554 19.474 48.9234 18.9959 47.9971C18.5178 47.0709 18.2788 46.0251 18.2788 44.8598C18.2788 43.6795 18.5178 42.6338 18.9959 41.7224C19.4889 40.7962 20.1463 40.0716 20.9679 39.5487C21.8046 39.0258 22.7383 38.7644 23.7691 38.7644C24.3817 38.7644 24.9419 38.854 25.4499 39.0333C25.9578 39.2126 26.3985 39.4665 26.772 39.7952C27.1605 40.1089 27.4742 40.475 27.7132 40.8933C27.9672 41.3116 28.1241 41.7598 28.1838 42.2379L27.6684 42.0586V39.0109H29.999V50.7759H27.6684V47.9747L28.2062 47.8178C28.1166 48.2212 27.9299 48.6171 27.646 49.0056C27.3771 49.379 27.026 49.7152 26.5928 50.014C26.1744 50.3128 25.7038 50.5518 25.1809 50.7311C24.673 50.9104 24.1352 51 23.5675 51ZM24.1725 48.8711C24.8747 48.8711 25.4947 48.6993 26.0325 48.3557C26.5703 48.0121 26.9887 47.5415 27.2874 46.9439C27.6012 46.3313 27.7581 45.6366 27.7581 44.8598C27.7581 44.0979 27.6012 43.4181 27.2874 42.8205C26.9887 42.2229 26.5703 41.7523 26.0325 41.4087C25.4947 41.0651 24.8747 40.8933 24.1725 40.8933C23.4853 40.8933 22.8728 41.0651 22.3349 41.4087C21.812 41.7523 21.3937 42.2229 21.08 42.8205C20.7812 43.4181 20.6318 44.0979 20.6318 44.8598C20.6318 45.6366 20.7812 46.3313 21.08 46.9439C21.3937 47.5415 21.812 48.0121 22.3349 48.3557C22.8728 48.6993 23.4853 48.8711 24.1725 48.8711Z" fill="#2E3092" />
      <path d="M34.4319 47.1007L34.3199 44.5909L39.9447 39.0109H43.0596L34.4319 47.1007ZM32.3254 50.7759V34.1928H34.6336V50.7759H32.3254ZM40.2584 50.7759L35.8213 45.5993L37.4572 44.0978L43.2165 50.7759H40.2584Z" fill="#2E3092" />
      <path d="M49.1527 51C47.9426 51 46.867 50.746 45.9258 50.2381C44.9995 49.7152 44.2674 49.0055 43.7296 48.1092C43.2067 47.2128 42.9453 46.1819 42.9453 45.0166C42.9453 44.0904 43.0947 43.2463 43.3935 42.4844C43.6923 41.7224 44.1031 41.0651 44.626 40.5123C45.1638 39.9446 45.7988 39.5113 46.5308 39.2126C47.2778 38.8988 48.0845 38.742 48.9511 38.742C49.713 38.742 50.4226 38.8914 51.08 39.1901C51.7373 39.474 52.305 39.8699 52.7831 40.3779C53.2761 40.8858 53.6496 41.4909 53.9036 42.193C54.1725 42.8803 54.2995 43.6347 54.2845 44.4564L54.2621 45.4424H44.6484L44.133 43.6048H52.2677L51.9315 43.9858V43.448C51.8867 42.955 51.7224 42.5142 51.4385 42.1258C51.1547 41.7374 50.7961 41.4311 50.3629 41.207C49.9296 40.9829 49.459 40.8709 48.9511 40.8709C48.1443 40.8709 47.4645 41.0277 46.9118 41.3415C46.359 41.6403 45.9407 42.0885 45.6568 42.686C45.373 43.2687 45.2311 43.9933 45.2311 44.8598C45.2311 45.6815 45.4029 46.3986 45.7465 47.0111C46.0901 47.6087 46.5756 48.0718 47.2031 48.4005C47.8306 48.7292 48.5551 48.8935 49.3768 48.8935C49.9595 48.8935 50.4973 48.7964 50.9903 48.6022C51.4983 48.408 52.0436 48.0569 52.6262 47.5489L53.7915 49.1848C53.433 49.5434 52.9922 49.8571 52.4694 50.126C51.9614 50.3949 51.4161 50.6116 50.8335 50.7759C50.2658 50.9253 49.7055 51 49.1527 51Z" fill="#2E3092" />
    </svg>

  );
};

export { NewLogo };
