const DetailIcon = (props: any) => {
  return (
    <svg width="23" height="23" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.49978 16.1C12.6906 16.1 16.1 12.6906 16.1 8.5C16.1 4.30914 12.6906 0.9 8.49978 0.9C4.30914 0.9 0.9 4.30915 0.9 8.5C0.9 12.6906 4.30914 16.1 8.49978 16.1ZM8.49978 1.74666C12.2237 1.74666 15.2533 4.77635 15.2533 8.5C15.2533 12.2239 12.2237 15.2533 8.49978 15.2533C4.77613 15.2533 1.74666 12.2237 1.74666 8.5C1.74666 4.77634 4.77613 1.74666 8.49978 1.74666Z" fill="#F135A3" stroke="#F135A3" stroke-width="0.2" />
      <path d="M7.72656 7.10679H7.62656V7.20679V11.3447V11.4447H7.72656H8.76105H8.86105V11.3447V7.20679V7.10679H8.76105H7.72656Z" fill="#F135A3" stroke="#F135A3" stroke-width="0.2" />
      <path d="M8.2437 5.27242C8.58397 5.27242 8.86105 4.99558 8.86105 4.65518C8.86105 4.31478 8.58397 4.03794 8.2437 4.03794C7.90337 4.03794 7.62656 4.31484 7.62656 4.65518C7.62656 4.99553 7.90337 5.27242 8.2437 5.27242ZM8.2437 4.64113C8.25136 4.64113 8.25773 4.64747 8.25773 4.65518C8.25773 4.66289 8.25136 4.66923 8.2437 4.66923C8.23637 4.66923 8.22988 4.66301 8.22988 4.65518C8.22988 4.64735 8.23637 4.64113 8.2437 4.64113Z" fill="#F135A3" stroke="#F135A3" stroke-width="0.2" />
    </svg>
  );
};

export { DetailIcon };
