export enum EMaterialsTabsIcon {
  UPDATE_PRICE_PER_TON = "updatePrice",
  UPDATE_UNIT_PRICE = "updatePrice",
  ADD_PERCENT_TO_UNIT_PRICE = "updatePricePercent",
  ADD_PERCENT_TO_PRICE_PER_TON = "updatePricePercent",
  UPDATE_CURRENCY = "currencyUpdate",
  CHANGE_TO_ACTIVE = "active",
  CHANGE_TO_INACTIVE = "unActive",
  ADD_NEW = "add",
  DUPLICATE = "duplicate",
  DELETE = "delete",
  DOWNLOAD_EXCEL = "excel",
  UPLOAD_EXCEL = "uploadFile",
  Upload_Materials_Pictures = "UploadMaterialsPictures",
  CREATE_PURCHASE_ORDER = "purchaseOrderIcon",

}
