const UploadExcelSheet = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 7.73778L1.51831 9.19494V7.73778H0Z" fill="#8283BE" />
      <path
        d="M10.7696 1.00006H2.45925C2.19811 1.00006 1.94766 1.09962 1.763 1.27684C1.57835 1.45406 1.47461 1.69442 1.47461 1.94504V16.6168C1.47461 16.8674 1.57835 17.1078 1.763 17.285C1.94766 17.4622 2.19811 17.5618 2.45925 17.5618H13.824C14.0851 17.5618 14.3356 17.4622 14.5202 17.285C14.7049 17.1078 14.8086 16.8674 14.8086 16.6168V4.88582L10.7696 1.00006Z"
        stroke="#8283BE"
        stroke-miterlimit="10"
      />
      <path
        d="M11.6222 4.88582H14.8164L10.7695 1.00006V4.06747C10.7695 4.28451 10.8594 4.49266 11.0193 4.64613C11.1792 4.7996 11.3961 4.88582 11.6222 4.88582Z"
        fill="#8283BE"
      />
      <path
        d="M8.74557 7.73777H0V3.48914H8.74557C8.89304 3.48964 9.03428 3.54621 9.13837 3.64646C9.24246 3.74671 9.30091 3.88247 9.30091 4.024V7.20291C9.30091 7.34444 9.24246 7.4802 9.13837 7.58045C9.03428 7.6807 8.89304 7.73727 8.74557 7.73777Z"
        fill="#8283BE"
      />
      <path
        d="M1.76758 6.59624L2.51197 5.50762L1.83847 4.50783H2.35049L2.7857 5.17876L3.215 4.50783H3.72307L3.04958 5.52085L3.792 6.59624H3.26817L2.78373 5.87427L2.29928 6.59624H1.76758Z"
        fill="white"
      />
      <path
        d="M4.01758 6.59625V4.51729H4.45082V6.24282H5.5418V6.59436L4.01758 6.59625Z"
        fill="white"
      />
      <path
        d="M5.76367 5.91774L6.19101 5.87616C6.19426 5.93262 6.20916 5.9879 6.23485 6.03879C6.26054 6.08969 6.2965 6.13518 6.34065 6.17262C6.3848 6.21007 6.43626 6.23873 6.49204 6.25695C6.54782 6.27516 6.60682 6.28256 6.6656 6.27872C6.95115 6.27872 7.09294 6.18233 7.09294 5.99145C7.09594 5.95942 7.08984 5.92721 7.07527 5.89826C7.06071 5.8693 7.03823 5.84468 7.01023 5.82702C6.8603 5.75851 6.70226 5.70774 6.53957 5.67582C6.07482 5.56243 5.84244 5.3539 5.84244 5.05025C5.83808 4.96656 5.85583 4.88317 5.89405 4.80784C5.93227 4.7325 5.98971 4.66766 6.06103 4.61934C6.23087 4.51109 6.43246 4.45809 6.63606 4.46814C7.17761 4.46814 7.45725 4.67793 7.47104 5.09939L7.03189 5.11829C7.02725 5.0734 7.01331 5.02987 6.99089 4.99023C6.96846 4.9506 6.93801 4.91566 6.90131 4.88749C6.86462 4.85932 6.82242 4.83847 6.77721 4.82617C6.73199 4.81388 6.68467 4.81038 6.63803 4.81589C6.39187 4.81589 6.26978 4.88393 6.26978 5.02001C6.27075 5.04458 6.27703 5.06869 6.28822 5.09081C6.2994 5.11293 6.31525 5.13259 6.33476 5.14853C6.46023 5.22016 6.59844 5.26886 6.7424 5.29216C7.10869 5.38288 7.33319 5.48116 7.41393 5.59267C7.49526 5.7032 7.53863 5.83534 7.53799 5.97066C7.53799 6.40157 7.24785 6.61703 6.66757 6.61703C6.12208 6.63404 5.82866 6.39401 5.76367 5.91774Z"
        fill="white"
      />
      <path
        d="M11.5051 9.50677H4.88241C4.79483 9.50702 4.70817 9.52385 4.62738 9.5563C4.54659 9.58875 4.47327 9.63619 4.41162 9.69588C4.34997 9.75558 4.3012 9.82636 4.26811 9.90418C4.23501 9.98199 4.21824 10.0653 4.21876 10.1494V15.2012C4.21824 15.3708 4.2878 15.5337 4.4122 15.6542C4.53661 15.7746 4.7057 15.8428 4.88241 15.8438H11.5051C11.682 15.8428 11.8513 15.7747 11.976 15.6543C12.1007 15.5339 12.1707 15.371 12.1707 15.2012V10.1494C12.171 10.0652 12.154 9.98186 12.1206 9.90403C12.0873 9.82621 12.0384 9.75544 11.9766 9.69577C11.9148 9.6361 11.8413 9.58869 11.7604 9.55627C11.6795 9.52384 11.5928 9.50702 11.5051 9.50677ZM11.5858 10.1494V11.4421H9.7288V10.0719H11.5012C11.5121 10.0714 11.523 10.073 11.5333 10.0767C11.5435 10.0803 11.5529 10.0859 11.5608 10.0932C11.5687 10.1004 11.575 10.1092 11.5793 10.1188C11.5836 10.1285 11.5858 10.1389 11.5858 10.1494ZM7.29478 13.3944V12.0393H9.17741V13.3944H7.29478ZM9.17741 13.9917V15.2806H7.29478V13.9917H9.17741ZM6.74732 13.3944H4.80364V12.0393H6.74732V13.3944ZM7.29478 11.4421V10.0719H9.17741V11.4421H7.29478ZM9.7288 12.0393H11.5858V13.3944H9.7288V12.0393ZM4.88241 10.0719H6.74732V11.4421H4.80364V10.1494C4.80338 10.1393 4.80522 10.1292 4.80906 10.1199C4.8129 10.1105 4.81866 10.1019 4.826 10.0947C4.83334 10.0875 4.84211 10.0817 4.8518 10.0778C4.86149 10.0739 4.8719 10.0719 4.88241 10.0719ZM4.80364 15.2012V13.9917H6.74732V15.2806H4.88241C4.8718 15.2804 4.86136 15.2781 4.85166 15.274C4.84196 15.2699 4.8332 15.264 4.82589 15.2566C4.81857 15.2492 4.81284 15.2405 4.80902 15.231C4.80521 15.2215 4.80338 15.2114 4.80364 15.2012ZM11.4992 15.2787H9.72684V13.9917H11.5839V15.2012C11.5839 15.2215 11.5756 15.2409 11.5609 15.2554C11.5462 15.2698 11.5262 15.2782 11.5051 15.2787H11.4992Z"
        fill="#8283BE"
      />
      <path
        d="M13.6242 18.5219C14.8836 18.5219 15.9046 17.542 15.9046 16.3333C15.9046 15.1246 14.8836 14.1447 13.6242 14.1447C12.3647 14.1447 11.3438 15.1246 11.3438 16.3333C11.3438 17.542 12.3647 18.5219 13.6242 18.5219Z"
        fill="#8283BE"
      />
      <path
        d="M14.2852 17.4446V16.4486H14.8543C14.8705 16.4472 14.8859 16.4414 14.8988 16.432C14.9118 16.4226 14.9217 16.4098 14.9274 16.3952C14.9332 16.3806 14.9345 16.3648 14.9314 16.3495C14.9282 16.3342 14.9206 16.3201 14.9094 16.3088L13.6727 15.0009C13.6659 14.9933 13.6574 14.9872 13.6479 14.983C13.6384 14.9788 13.628 14.9767 13.6176 14.9767C13.6071 14.9767 13.5968 14.9788 13.5873 14.983C13.5777 14.9872 13.5693 14.9933 13.5624 15.0009L12.3395 16.2955C12.3285 16.3071 12.3211 16.3213 12.318 16.3367C12.3149 16.352 12.3163 16.3679 12.322 16.3826C12.3277 16.3972 12.3375 16.4101 12.3503 16.4197C12.3631 16.4294 12.3785 16.4354 12.3947 16.4373H12.9854V17.439C12.9849 17.4494 12.9865 17.4598 12.9902 17.4696C12.9939 17.4795 12.9995 17.4885 13.0069 17.4962C13.0142 17.5039 13.023 17.5102 13.0328 17.5146C13.0427 17.5191 13.0533 17.5216 13.0642 17.5221H14.2162C14.2354 17.519 14.2528 17.5095 14.2654 17.4954C14.278 17.4812 14.285 17.4632 14.2852 17.4446Z"
        fill="white"
      />
    </svg>
  );
};

export { UploadExcelSheet };
