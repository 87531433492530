const ActiveMaterial = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.239 3.05176e-05H3.34876C1.49929 3.05176e-05 0 1.59881 0 3.57101V3.65081C0 5.623 1.49929 7.22179 3.34876 7.22179H10.239C12.0884 7.22179 13.5877 5.623 13.5877 3.65081V3.57101C13.5877 1.59881 12.0884 3.05176e-05 10.239 3.05176e-05Z"
        fill="#8283BE"
      />
      <path
        d="M10.2989 6.16645C11.6224 6.16645 12.6954 5.0223 12.6954 3.61091C12.6954 2.19952 11.6224 1.05537 10.2989 1.05537C8.9753 1.05537 7.90234 2.19952 7.90234 3.61091C7.90234 5.0223 8.9753 6.16645 10.2989 6.16645Z"
        fill="white"
      />
    </svg>
  );
};

export { ActiveMaterial };
