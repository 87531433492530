export * from "./widjet-name";
export * from "./waiting-auth";
export * from "./login";
export * from "./loading";
export * from "./admin-login";
export * from "./header-title";
export * from "./delete-material-modal";
export * from "./shared-admin-customers";
export * from "./forgot-password"
export * from "./reset-password"
export * from "./login-by-code"
export * from "./machine-list"