const TabCloseIcon = (props: any) => {
  return (
    <svg
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-2.47955e-05 1.42491C-2.47955e-05 0.974909 0.204309 0.662242 0.612975 0.486909C1.02164 0.311576 1.38398 0.382575 1.69998 0.699908L4.29998 3.29991C4.39998 3.39991 4.47497 3.50824 4.52497 3.62491C4.57497 3.74158 4.59998 3.86658 4.59998 3.99991C4.59998 4.13324 4.57497 4.25824 4.52497 4.37491C4.47497 4.49158 4.39998 4.59991 4.29998 4.69991L1.69998 7.29991C1.38331 7.61658 1.02064 7.68724 0.611976 7.51191C0.203309 7.33658 -0.000691414 7.02424 -2.47955e-05 6.57491L-2.47955e-05 1.42491Z"
        fill="white"
      />
    </svg>
  );
};

export { TabCloseIcon };
