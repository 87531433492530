const SortIcon = () => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="0.5" x2="14" y2="0.5" stroke="black" stroke-opacity="0.56" />
      <line y1="4.5" x2="14" y2="4.5" stroke="black" stroke-opacity="0.56" />
      <line y1="8.5" x2="14" y2="8.5" stroke="black" stroke-opacity="0.56" />
    </svg>
  );
};

export { SortIcon };
