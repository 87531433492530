import { getSetApiData } from "@/services/api-service/get-set-api-data";
import { EHttpMethod } from "@/services/api-service/enums";
import { ICallAndSetData } from "@/services/api-service/interface";

const GET_COMPANY_PROFILE_URL = "/v1/get-print-house-profile";
const UPDATE_COMPANY_PROFILE_URL = "/v1/update-print-house-profile";
const UPDATE_COMPANY_LOGO_URL = "/v1/update-print-house-profile-image";
const GET_COMPANY_LOGIN_LOGO_URL = "/v1/get-print-house-login-logo";
const CHECk_PRINT_HOUSE_DOMAIN = "/v1/check-print-house-domain";
const GET_ALL_CoUNTRIES_URL = "/v1/admins/get-all-countries";
const CREATE_NEW_PRINT_HOUSE = "/v1/create-new-print-house";
const GET_COMPANY_LOCATIONS_URL = "/v1/printhouses/get-print-house-locations";
const ADD_COMPANY_LOCATION_URL = "/v1/print-house/add-location";
const DELETE_COMPANY_LOCATION_URL = "/v1/print-house/delete-location/";
const CHECk_PRINT_HOUSE_TAX_LOGIN = "/v1/print-houses/check-tax-login";
const SAVE_PRINT_HOUSE_PAYMENT_DETAILS_URL =
  "/v1/print-houses/save-print-house-payment-details";
const MAKE_PAYMENT_URL = "/v1/print-houses/make-payment";

const getCompanyProfile: ICallAndSetData = async (callApi, setState) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.GET,
    GET_COMPANY_PROFILE_URL,
    setState
  );
};
const getCompanyLoginLogo: ICallAndSetData = async (callApi, setState) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.GET,
    GET_COMPANY_LOGIN_LOGO_URL,
    setState
  );
};

const updateCompanyProfile: ICallAndSetData = async (
  callApi,
  setState,
  profile
) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.POST,
    UPDATE_COMPANY_PROFILE_URL,
    setState,
    profile
  );
};

const updateCompanyLogo: ICallAndSetData = async (callApi, setState, data) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.POST,
    UPDATE_COMPANY_LOGO_URL,
    setState,
    data
  );
};
const checkPrintHouseDomainApi: ICallAndSetData = async (
  callApi,
  setState,
  data
) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.GET,
    CHECk_PRINT_HOUSE_DOMAIN,
    setState,
    data
  );
};
const getAllCountriesApi: ICallAndSetData = async (callApi, setState) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.GET,
    GET_ALL_CoUNTRIES_URL,
    setState
  );
};

const createNewPrintHouseApi: ICallAndSetData = async (
  callApi,
  setState,
  data
) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.POST,
    CREATE_NEW_PRINT_HOUSE,
    setState,
    data
  );
};

const getCompanyLocationsApi: ICallAndSetData = async (callApi, setState) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.GET,
    GET_COMPANY_LOCATIONS_URL,
    setState
  );
};
const companyAddLocationApi: ICallAndSetData = async (
  callApi,
  setState,
  data
) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.POST,
    ADD_COMPANY_LOCATION_URL,
    setState,
    data
  );
};

const companyDeleteLocationApi: ICallAndSetData = async (
  callApi,
  setState,
  data
) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.DELETE,
    DELETE_COMPANY_LOCATION_URL + data,
    setState
  );
};

const checkPrintHouseTaxLoginApi: ICallAndSetData = async (
  callApi,
  setState
) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.GET,
    CHECk_PRINT_HOUSE_TAX_LOGIN,
    setState
  );
};

const savePrintHousePaymentDetailsApi: ICallAndSetData = async (
  callApi,
  setState,
  data
) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.POST,
    SAVE_PRINT_HOUSE_PAYMENT_DETAILS_URL,
    setState,
    data
  );
};

const makePaymentApi: ICallAndSetData = async (callApi, setState, data) => {
  return await getSetApiData(
    callApi,
    EHttpMethod.POST,
    MAKE_PAYMENT_URL,
    setState,
    data
  );
};

export {
  getCompanyProfile,
  updateCompanyProfile,
  updateCompanyLogo,
  getCompanyLoginLogo,
  checkPrintHouseDomainApi,
  getAllCountriesApi,
  createNewPrintHouseApi,
  getCompanyLocationsApi,
  companyAddLocationApi,
  companyDeleteLocationApi,
  checkPrintHouseTaxLoginApi,
  savePrintHousePaymentDetailsApi,
  makePaymentApi,
};
