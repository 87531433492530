const OrderNowIcon = (props: any) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.25 45.8337H18.75C8.33335 45.8337 4.16669 41.667 4.16669 31.2503V18.7503C4.16669 8.33366 8.33335 4.16699 18.75 4.16699H31.25C41.6667 4.16699 45.8334 8.33366 45.8334 18.7503V31.2503C45.8334 41.667 41.6667 45.8337 31.25 45.8337Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.465 29.8135C27.2564 29.8134 27.0525 29.8743 26.879 29.9884C26.7056 30.1024 26.5704 30.2646 26.4905 30.4543C26.4107 30.644 26.3897 30.8528 26.4304 31.0542C26.471 31.2556 26.5714 31.4406 26.7189 31.5859C26.8664 31.7311 27.0543 31.8301 27.2589 31.8702C27.4634 31.9103 27.6755 31.8897 27.8682 31.8112C28.061 31.7326 28.2257 31.5996 28.3416 31.4288C28.4575 31.2581 28.5194 31.0574 28.5195 30.852C28.5194 30.5767 28.4083 30.3126 28.2106 30.1179C28.0129 29.9231 27.7447 29.8136 27.465 29.8135Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.4245 29.8135C23.216 29.8135 23.0121 29.8744 22.8386 29.9885C22.6652 30.1025 22.53 30.2647 22.4502 30.4544C22.3703 30.6441 22.3494 30.8529 22.3901 31.0543C22.4308 31.2557 22.5312 31.4407 22.6787 31.586C22.8262 31.7312 23.0141 31.8301 23.2187 31.8702C23.4232 31.9103 23.6353 31.8897 23.828 31.8112C24.0207 31.7326 24.1854 31.5996 24.3014 31.4288C24.4173 31.2581 24.4792 31.0574 24.4792 30.852C24.4791 30.5766 24.3679 30.3126 24.1702 30.1178C23.9724 29.9231 23.7042 29.8136 23.4245 29.8135Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.5507 20.6153H20.5324L20.236 18.448C20.1932 18.1348 20.0155 18 19.7001 18H17.5275C17.3876 18 17.2534 18.0547 17.1545 18.1521C17.0556 18.2495 17 18.3816 17 18.5194C17 18.6571 17.0556 18.7892 17.1545 18.8866C17.2534 18.984 17.3876 19.0387 17.5275 19.0387H19.2474L20.4798 26.4214C20.6851 27.6922 20.9801 28.9236 22.6577 28.9236H28.4586C30.157 28.9236 30.3438 27.7304 30.6486 26.5295L31.9426 21.4322C32.0426 21.0384 32.0672 20.6153 31.5507 20.6153ZM30.4983 22.9669H23.3198C23.18 22.9672 23.0461 23.022 22.9473 23.1193C22.8485 23.2166 22.7928 23.3485 22.7924 23.486C22.7928 23.6236 22.8485 23.7555 22.9473 23.8527C23.0461 23.95 23.18 24.0048 23.3198 24.0052H30.2345L29.9343 25.1907H24.611C24.4713 25.1912 24.3374 25.246 24.2387 25.3433C24.1399 25.4406 24.0843 25.5724 24.0838 25.7099C24.0843 25.8474 24.1399 25.9792 24.2387 26.0764C24.3375 26.1736 24.4713 26.2284 24.611 26.2289H29.6697L29.6137 26.4493C29.4472 27.1062 29.409 27.8833 28.4586 27.8833H22.6577C21.6535 27.8833 21.6283 26.9947 21.5088 26.2884L20.7239 21.6537H30.8317L30.4983 22.9669Z"
        fill="white"
      />
    </svg>
  );
};

export { OrderNowIcon };
