const DirectboxSendIcon = (props: any) => {
  return (
    <svg width={props.width || "24"} height={props.height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 8V2L10 4" stroke={props.stroke || "#292D32"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 2L14 4" stroke={props.stroke || "#292D32"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 12C3 12 3 13.79 3 16V17C3 19.76 3 22 8 22H16C20 22 21 19.76 21 17V16C21 13.79 21 12 17 12C16 12 15.72 12.21 15.2 12.6L14.18 13.68C13 14.94 11 14.94 9.81 13.68L8.8 12.6C8.28 12.21 8 12 7 12Z" stroke={props.stroke || "#292D32"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 12V10C5 7.99004 5 6.33004 8 6.04004" stroke={props.stroke || "#292D32"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 12V10C19 7.99004 19 6.33004 16 6.04004" stroke={props.stroke || "#292D32"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
};

export { DirectboxSendIcon };
