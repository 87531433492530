import { IIconProps } from "@/components/icons/interface";

const TickMoveIcon = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 17L13 15L11 13L13 15H7"
        stroke={color || "#8283BE"}
        stroke-width="2.25"  
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
        stroke={color || "#8283BE"}
        stroke-width="2.25"  
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
        stroke={color || "#8283BE"}
        stroke-width="2.25"  
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { TickMoveIcon };