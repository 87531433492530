const PercentageMaterial = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.02662 11.3099H5.58511V10.2893C5.01727 10.3083 4.4553 10.1683 3.96124 9.88489V9.21017C4.07196 9.29495 4.19034 9.36898 4.31482 9.4313C4.44672 9.49691 4.583 9.55311 4.72266 9.59951C4.86506 9.6465 5.01077 9.68251 5.15856 9.70723C5.29952 9.73108 5.44219 9.74309 5.58511 9.74314V7.03294L4.99954 6.69841C4.80976 6.59072 4.63329 6.46067 4.47384 6.31097C4.31921 6.16403 4.19242 5.98982 4.09968 5.7969C3.99683 5.57611 3.94684 5.33397 3.95376 5.09006C3.95167 4.86125 3.99295 4.63415 4.07536 4.42101C4.1528 4.21996 4.26775 4.03581 4.41398 3.87859C4.56196 3.72199 4.73693 3.5939 4.93032 3.5006C5.13687 3.39684 5.35858 3.32728 5.58698 3.29459V2.40442H6.02849V3.27191C6.27997 3.27581 6.53052 3.30366 6.77682 3.35507C6.96386 3.39603 7.14538 3.45948 7.31748 3.54407V4.18287C6.93158 3.94235 6.48561 3.8184 6.03223 3.82567V6.59825C6.30671 6.73385 6.57118 6.88923 6.82359 7.06318C7.01295 7.19088 7.18523 7.3427 7.33619 7.51488C7.45712 7.65264 7.55108 7.81233 7.61307 7.98548C7.66847 8.15752 7.69564 8.33755 7.69352 8.51845C7.69545 8.73471 7.65738 8.94944 7.58127 9.15159C7.50669 9.34528 7.39549 9.52246 7.25387 9.67321C7.10362 9.82968 6.92685 9.9577 6.73192 10.0512C6.51037 10.1561 6.27452 10.2268 6.03223 10.261L6.02662 11.3099ZM5.58511 3.84457C5.30775 3.89028 5.05388 4.0295 4.86484 4.23957C4.68324 4.45381 4.58719 4.72874 4.59545 5.01068C4.59358 5.14199 4.60931 5.27296 4.64222 5.40001C4.67441 5.5187 4.72835 5.63025 4.80124 5.72886C4.88839 5.84466 4.99102 5.9477 5.10618 6.03504C5.25988 6.15144 5.42183 6.2563 5.59072 6.34877L5.58511 3.84457ZM6.02662 9.71857C6.3169 9.68264 6.58693 9.54957 6.79365 9.34058C6.96856 9.13738 7.06021 8.87447 7.04996 8.60539C7.0506 8.48108 7.0323 8.35741 6.9957 8.23873C6.95206 8.11928 6.88881 8.00811 6.80862 7.90988C6.7174 7.79276 6.61107 7.68852 6.49245 7.59993C6.34109 7.48485 6.18235 7.38005 6.01727 7.28619L6.02662 9.71857Z"
        fill="#8283BE"
      />
      <path
        d="M8.89219 0.0157185C10.6344 -0.0912214 12.3612 0.40035 13.7919 1.41051C15.7749 2.76939 17.12 4.6329 17.6214 7.05583C17.7519 7.57561 17.8105 8.11115 17.7954 8.64718C17.7786 8.89476 17.8684 8.96658 18.0966 8.9439C18.2837 8.92689 18.4577 8.95335 18.6373 8.9439C19.0507 8.9061 19.0638 9.16125 18.9104 9.40316C18.4165 10.1837 17.8927 10.9416 17.3707 11.7032C17.1836 11.9773 16.9966 11.8809 16.8394 11.6579C16.353 10.9284 15.8685 10.1951 15.3933 9.45797C15.1763 9.12156 15.2623 8.9628 15.6402 8.94768C16.5158 8.91177 16.4671 8.91744 16.3736 8.0027C16.2371 6.64977 15.7131 5.3664 14.8657 4.30971C13.7694 2.93571 12.37 1.95104 10.6208 1.55415C8.96958 1.14373 7.22782 1.33723 5.70432 2.10035C3.41444 3.257 1.96082 5.10161 1.51557 7.67952C1.3191 8.70052 1.34012 9.75209 1.57721 10.7642C1.8143 11.7764 2.26203 12.726 2.89061 13.5497C3.46095 14.3378 4.17951 15.0045 5.00516 15.5117C5.83081 16.0189 6.74735 16.3566 7.70235 16.5056C9.87063 16.8685 11.8181 16.3166 13.5561 14.9937C14.0459 14.5871 14.494 14.1318 14.8938 13.6348C15.1875 13.3022 15.5373 13.1963 15.8778 13.425C16.2183 13.6537 16.2744 14.0411 16.0125 14.4361C15.4296 15.2776 14.6884 15.995 13.8311 16.5472C12.6864 17.3338 11.3645 17.8173 9.98662 17.9534C7.71732 18.1631 5.58646 17.7171 3.70816 16.3204C1.82314 14.9548 0.547773 12.8928 0.159224 10.5825C-0.319704 7.97813 0.295794 5.61379 1.81677 3.51971C3.07583 1.78283 4.81381 0.694215 6.89229 0.187705C7.54621 0.0268851 8.22077 -0.0311252 8.89219 0.0157185Z"
        fill="#8283BE"
      />
      <path
        d="M8.93206 13.4588C8.50738 13.4588 8.31656 13.1092 8.47558 12.7198C8.52034 12.6224 8.58395 12.535 8.66266 12.4628C10.1593 10.9508 11.6516 9.43886 13.1395 7.92689C13.4856 7.57536 13.8972 7.59804 14.0749 7.96469C14.1288 8.0569 14.149 8.16529 14.1319 8.27098C14.1148 8.37666 14.0615 8.47293 13.9814 8.54302C12.4099 10.1268 10.8415 11.7144 9.27629 13.3057C9.15468 13.4172 9.04992 13.5023 8.93206 13.4588Z"
        fill="#8283BE"
      />
      <path
        d="M9.50041 7.25974C8.62487 7.25974 7.92893 7.95524 8.00376 8.80005C7.9046 9.66755 8.78763 10.3442 9.48731 10.3271C9.88093 10.3156 10.2547 10.15 10.5298 9.86545C10.805 9.58086 10.96 9.19946 10.9622 8.80166C10.9644 8.40385 10.8136 8.02074 10.5416 7.73309C10.2696 7.44543 9.89761 7.27572 9.50415 7.25974H9.50041ZM9.50041 9.30467C9.43408 9.30567 9.3682 9.29345 9.30654 9.26872C9.24488 9.244 9.18864 9.20724 9.14104 9.16056C9.09345 9.11388 9.05541 9.05818 9.02912 8.99665C9.00283 8.93512 8.98879 8.86896 8.98781 8.80194C8.98682 8.73493 8.99892 8.66838 9.02339 8.60609C9.04787 8.5438 9.08425 8.48699 9.13046 8.4389C9.17667 8.39081 9.2318 8.35239 9.29271 8.32583C9.35362 8.29927 9.41911 8.28509 9.48544 8.2841C9.61968 8.28911 9.74769 8.3426 9.84621 8.43485C9.94473 8.5271 10.0072 8.65198 10.0224 8.78682C10.0108 8.92315 9.95087 9.05066 9.85356 9.14583C9.75626 9.241 9.62817 9.29742 9.49293 9.30467H9.50041Z"
        fill="#8283BE"
      />
      <path
        d="M13.0556 10.8355C12.6549 10.8344 12.2697 10.9922 11.9829 11.2749C11.696 11.5576 11.5304 11.9427 11.5216 12.3475C11.5183 12.6497 11.6037 12.9461 11.7671 13.1993C11.9305 13.4525 12.1644 13.6511 12.4395 13.77C12.7145 13.8889 13.0183 13.9228 13.3124 13.8674C13.6065 13.812 13.8777 13.6698 14.0919 13.4588C14.306 13.2477 14.4534 12.9773 14.5155 12.6816C14.5776 12.386 14.5516 12.0784 14.4407 11.7977C14.3298 11.517 14.139 11.2758 13.8925 11.1045C13.646 10.9333 13.3548 10.8397 13.0556 10.8355ZM13.0295 12.8748C12.9011 12.8668 12.78 12.8116 12.6891 12.7197C12.5981 12.6278 12.5435 12.5055 12.5356 12.3759C12.5454 12.2454 12.5995 12.1224 12.6888 12.0276C12.7782 11.9329 12.8972 11.8723 13.0257 11.8561C13.1616 11.8639 13.2898 11.9223 13.3855 12.0201C13.4812 12.1178 13.5377 12.248 13.5439 12.3853C13.5267 12.5141 13.4662 12.633 13.3725 12.7221C13.2789 12.8112 13.1578 12.8651 13.0295 12.8748Z"
        fill="#8283BE"
      />
    </svg>
  );
};

export { PercentageMaterial };
