const DownloadExcelSheet = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 7.97248L1.50792 9.4804V7.97248H0Z" fill="#8283BE" />
      <path
        d="M10.6962 1.00006H2.44274C2.18339 1.00006 1.93466 1.10309 1.75126 1.28648C1.56787 1.46987 1.46484 1.71861 1.46484 1.97796V17.1608C1.46484 17.4202 1.56787 17.6689 1.75126 17.8523C1.93466 18.0357 2.18339 18.1387 2.44274 18.1387H13.7297C13.989 18.1387 14.2377 18.0357 14.4211 17.8523C14.6045 17.6689 14.7076 17.4202 14.7076 17.1608V5.02118L10.6962 1.00006Z"
        stroke="#8283BE"
        stroke-miterlimit="10"
      />
      <path
        d="M11.5422 5.02118H14.7145L10.6953 1.00006V4.17432C10.6953 4.39892 10.7845 4.61432 10.9434 4.77314C11.1022 4.93196 11.3176 5.02118 11.5422 5.02118Z"
        fill="#8283BE"
      />
      <path
        d="M8.6857 7.97248H0V3.57584H8.6857C8.83216 3.57636 8.97243 3.6349 9.07581 3.73865C9.17919 3.84239 9.23724 3.98288 9.23724 4.12933V7.41899C9.23724 7.56544 9.17919 7.70593 9.07581 7.80967C8.97243 7.91342 8.83216 7.97196 8.6857 7.97248Z"
        fill="#8283BE"
      />
      <path
        d="M1.75586 6.79118L2.49515 5.66464L1.82627 4.63002H2.33478L2.76701 5.32433L3.19337 4.63002H3.69797L3.02908 5.67833L3.76642 6.79118H3.24618L2.76505 6.04406L2.28393 6.79118H1.75586Z"
        fill="white"
      />
      <path
        d="M3.99023 6.79118V4.6398H4.42051V6.42544H5.50402V6.78922L3.99023 6.79118Z"
        fill="white"
      />
      <path
        d="M5.72461 6.08905L6.14902 6.04602C6.15225 6.10445 6.16705 6.16166 6.19256 6.21432C6.21807 6.26699 6.25379 6.31406 6.29764 6.35281C6.34149 6.39157 6.39259 6.42123 6.44799 6.44008C6.50339 6.45892 6.56198 6.46658 6.62037 6.46261C6.90396 6.46261 7.04477 6.36286 7.04477 6.16533C7.04776 6.13219 7.0417 6.09886 7.02723 6.06889C7.01277 6.03892 6.99044 6.01345 6.96263 5.99517C6.81373 5.92428 6.65677 5.87174 6.49519 5.83871C6.03363 5.72136 5.80284 5.50557 5.80284 5.19134C5.79851 5.10474 5.81614 5.01845 5.85409 4.94049C5.89205 4.86252 5.9491 4.79542 6.01994 4.74542C6.18861 4.63341 6.38881 4.57856 6.59103 4.58895C7.12887 4.58895 7.4066 4.80605 7.42029 5.24219L6.98414 5.26175C6.97954 5.2153 6.9657 5.17025 6.94342 5.12923C6.92115 5.08822 6.89091 5.05207 6.85446 5.02291C6.81802 4.99376 6.77611 4.97218 6.73121 4.95946C6.6863 4.94673 6.63931 4.94312 6.59298 4.94882C6.34851 4.94882 6.22725 5.01923 6.22725 5.16005C6.22822 5.18548 6.23445 5.21042 6.24556 5.23332C6.25667 5.25621 6.27241 5.27655 6.29179 5.29304C6.4164 5.36717 6.55367 5.41757 6.69664 5.44168C7.06042 5.53556 7.28338 5.63726 7.36357 5.75265C7.44435 5.86704 7.48742 6.00378 7.48678 6.14381C7.48678 6.58974 7.19863 6.8127 6.62232 6.8127C6.08056 6.8303 5.78915 6.58191 5.72461 6.08905Z"
        fill="white"
      />
      <path
        d="M11.4259 9.80311H4.84857C4.76159 9.80336 4.67552 9.82078 4.59529 9.85436C4.51505 9.88794 4.44224 9.93703 4.38101 9.9988C4.31978 10.0606 4.27134 10.1338 4.23847 10.2144C4.2056 10.2949 4.18895 10.3811 4.18946 10.4681V15.6959C4.18894 15.8714 4.25803 16.04 4.38158 16.1646C4.50513 16.2893 4.67307 16.3599 4.84857 16.3609H11.4259C11.6016 16.3599 11.7697 16.2894 11.8936 16.1648C12.0175 16.0402 12.087 15.8716 12.087 15.6959V10.4681C12.0872 10.381 12.0703 10.2947 12.0373 10.2142C12.0042 10.1337 11.9556 10.0604 11.8942 9.99868C11.8328 9.93693 11.7598 9.88788 11.6795 9.85432C11.5992 9.82077 11.513 9.80336 11.4259 9.80311ZM11.5061 10.4681V11.8058H9.66179V10.3879H11.422C11.4329 10.3874 11.4437 10.389 11.4539 10.3928C11.4641 10.3966 11.4734 10.4024 11.4813 10.4099C11.4891 10.4174 11.4954 10.4265 11.4997 10.4365C11.5039 10.4465 11.5061 10.4572 11.5061 10.4681ZM7.24442 13.8262V12.4239H9.11416V13.8262H7.24442ZM9.11416 14.4442V15.7781H7.24442V14.4442H9.11416ZM6.70071 13.8262H4.77034V12.4239H6.70071V13.8262ZM7.24442 11.8058V10.3879H9.11416V11.8058H7.24442ZM9.66179 12.4239H11.5061V13.8262H9.66179V12.4239ZM4.84857 10.3879H6.70071V11.8058H4.77034V10.4681C4.77008 10.4576 4.77191 10.4473 4.77572 10.4375C4.77954 10.4278 4.78526 10.419 4.79255 10.4115C4.79984 10.404 4.80855 10.3981 4.81817 10.394C4.82779 10.39 4.83813 10.3879 4.84857 10.3879ZM4.77034 15.6959V14.4442H6.70071V15.7781H4.84857C4.83804 15.7778 4.82766 15.7755 4.81803 15.7712C4.8084 15.767 4.7997 15.7608 4.79243 15.7532C4.78517 15.7456 4.77948 15.7366 4.77569 15.7268C4.77189 15.7169 4.77008 15.7065 4.77034 15.6959ZM11.4201 15.7761H9.65983V14.4442H11.5042V15.6959C11.5042 15.7169 11.496 15.737 11.4814 15.7519C11.4667 15.7669 11.4468 15.7756 11.4259 15.7761H11.4201Z"
        fill="#8283BE"
      />
      <path
        d="M13.5304 19.1323C14.7813 19.1323 15.7953 18.1183 15.7953 16.8674C15.7953 15.6166 14.7813 14.6026 13.5304 14.6026C12.2796 14.6026 11.2656 15.6166 11.2656 16.8674C11.2656 18.1183 12.2796 19.1323 13.5304 19.1323Z"
        fill="#8283BE"
      />
      <path
        d="M12.8754 15.7194V16.7423H12.3101C12.2941 16.7442 12.2788 16.7505 12.2661 16.7605C12.2534 16.7704 12.2436 16.7837 12.238 16.7989C12.2323 16.8141 12.231 16.8305 12.234 16.8464C12.2371 16.8623 12.2445 16.877 12.2554 16.889L13.4817 18.2424C13.4888 18.2497 13.4973 18.2555 13.5067 18.2595C13.5161 18.2635 13.5262 18.2656 13.5364 18.2656C13.5466 18.2656 13.5568 18.2635 13.5662 18.2595C13.5756 18.2555 13.5841 18.2497 13.5912 18.2424L14.8077 16.9007C14.8188 16.889 14.8263 16.8743 14.8295 16.8585C14.8326 16.8427 14.8313 16.8263 14.8256 16.8112C14.8199 16.7961 14.81 16.7829 14.7972 16.7732C14.7843 16.7634 14.769 16.7574 14.7529 16.756H14.1662V15.7194C14.1667 15.7086 14.1651 15.6978 14.1615 15.6877C14.1578 15.6775 14.1522 15.6681 14.1449 15.6601C14.1377 15.6522 14.1289 15.6457 14.1191 15.6411C14.1093 15.6365 14.0988 15.6338 14.088 15.6333H12.9516C12.9302 15.6349 12.9102 15.6447 12.8959 15.6608C12.8817 15.6769 12.8743 15.6979 12.8754 15.7194Z"
        fill="white"
      />
    </svg>
  );
};

export { DownloadExcelSheet };
