export enum ESourceView {
  ProductEditView,
  ProductCalculationView,
}

export enum EUpdateActionType {
  hidden,
  required,
  lock,
  showToClient,
  hiddenValue,
  defaultValue,
}
