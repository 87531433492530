import { ITheme } from "@/store";

export const defaultTheme: ITheme = {
  themeMode: "light",
  primary: {
    light: {
      500: "#2E3092",
      400: "#5859A8",
      300: "#8283BE",
      200: "#ABACD3",
      100: "#D5D6E9",
      50: "#EBECFF",
    },
    dark: {
      500: "#2E3092",
      600: "#252675",
      700: "#1C1D58",
      800: "#12133A",
      900: "#090A1D",
    },
  },
  second: {
    light: {
      500: "#ED028C",
      400: "#F135A3",
      300: "#F467BA",
      200: "#F89AD1",
      100: "#FBCCE8",
    },
    dark: {
      500: "#ED028C",
      600: "#BE0270",
      700: "#8E0154",
      800: "#5F0138",
      900: "#2F001C",
    },
  },
  success: {
    light: {
      500: "#40CC4E",
      400: "#66D671",
      300: "#8CE095",
      200: "#B3EBB8",
      100: "#D9F5DC",
    },
    dark: {
      500: "#40CC4E",
      600: "#33A33E",
      700: "#267A2F",
      800: "#1A521F",
      900: "#0D2910",
    },
  },
  warning: {
    light: {
      500: "#ECBD17",
      400: "#F0CA45",
      300: "#F4D774",
      200: "#F7E5A2",
      100: "#FBF2D1",
    },
    dark: {
      500: "#ECBD17",
      600: "#BD9712",
      700: "#8E710E",
      800: "#5E4C09",
      900: "#2F2605",
    },
  },
  error: {
    light: {
      500: "#D92C2C",
      400: "#E15656",
      300: "#E88080",
      200: "#F0ABAB",
      100: "#F7D5D5",
    },
    dark: {
      500: "#D92C2C",
      600: "#AE2323",
      700: "#821A1A",
      800: "#571212",
      900: "#2B0909",
    },
  },
  neutral: {
    light: {
      500: "#9E9E9E",
      400: "#B1B1B1",
      300: "#C5C5C5",
      200: "#D8D8D8",
      100: "#ECECEC",
    },
    dark: {
      500: "#9E9E9E",
      600: "#7E7E7E",
      700: "#5F5F5F",
      800: "#3F3F3F",
      900: "#202020",
    },
  },
  gray: {
    light: {
      500: "#667085",
      400: "#667085",
      300: "#667085",
      200: "#EAECF0",
      100: "#F2F4F7",
    },
    dark: {
      500: "#667085",
      600: "#667085",
      700: "#344054",
      800: "#EAECF0",
      900: "#101828",
    },
  },
};
