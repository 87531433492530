const WarningIcon = (props: any) => {
  return (
    <svg
      width={props.width || "26"}
      height={props.height || "26"}
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3157 2.99911C16.9714 2.46138 16.4301 2.16553 15.7916 2.16553C15.153 2.16553 14.6121 2.46138 14.2675 2.99911L3.04081 20.5163C2.8656 20.7898 2.76722 21.1055 2.75597 21.4302C2.74472 21.7549 2.82102 22.0766 2.97688 22.3616C3.13273 22.6467 3.3624 22.8845 3.6418 23.0503C3.9212 23.2161 4.24005 23.3036 4.56492 23.3037H27.0182C27.3431 23.3038 27.6621 23.2163 27.9416 23.0506C28.2211 22.8849 28.4509 22.647 28.6068 22.3619C28.7627 22.0769 28.839 21.755 28.8277 21.4303C28.8164 21.1056 28.718 20.7898 28.5426 20.5163L17.3157 2.99911ZM15.7916 21.0215C15.5665 21.0215 15.3465 20.9547 15.1594 20.8297C14.9723 20.7047 14.8264 20.527 14.7403 20.319C14.6542 20.1111 14.6316 19.8823 14.6755 19.6616C14.7195 19.4409 14.8278 19.2381 14.987 19.079C15.1461 18.9199 15.3488 18.8115 15.5696 18.7676C15.7903 18.7237 16.0191 18.7462 16.227 18.8323C16.4349 18.9185 16.6126 19.0643 16.7377 19.2514C16.8627 19.4386 16.9294 19.6586 16.9294 19.8836C16.9294 20.1854 16.8096 20.4748 16.5962 20.6882C16.3828 20.9016 16.0933 21.0215 15.7916 21.0215ZM17.0013 6.74045L16.2591 16.7375C16.2536 16.8114 16.2204 16.8804 16.1661 16.9307C16.1118 16.981 16.0405 17.0089 15.9665 17.0087H15.6154C15.5413 17.0089 15.47 16.981 15.4157 16.9307C15.3614 16.8804 15.3282 16.8114 15.3228 16.7375L14.5802 6.74045C14.5772 6.70021 14.5825 6.65977 14.5958 6.62168C14.6091 6.58359 14.6302 6.54867 14.6577 6.51911C14.6852 6.48955 14.7185 6.466 14.7555 6.44993C14.7925 6.43387 14.8324 6.42563 14.8728 6.42575H16.7087C16.749 6.42563 16.789 6.43387 16.826 6.44993C16.863 6.466 16.8963 6.48955 16.9238 6.51911C16.9513 6.54867 16.9724 6.58359 16.9857 6.62168C16.999 6.65977 17.0043 6.70021 17.0013 6.74045Z"
        fill="#ED028C"
      />
      <path
        d="M30.3658 19.3476L19.1391 1.83044C17.5751 -0.610147 14.0093 -0.610147 12.4452 1.83044L1.21885 19.3476C-0.47691 21.9937 1.42302 25.4681 4.56747 25.4681H27.0185C30.1613 25.4681 32.0612 21.9937 30.3658 19.3476ZM29.2399 22.7076C29.0248 23.1087 28.7042 23.4434 28.3128 23.6755C27.9213 23.9077 27.4739 24.0285 27.0188 24.0249H4.56552C4.11112 24.0248 3.66512 23.9024 3.2743 23.6706C2.88348 23.4387 2.56223 23.106 2.34423 22.7073C2.12623 22.3087 2.01951 21.8586 2.03527 21.4045C2.05103 20.9504 2.18867 20.5089 2.43378 20.1262L13.6604 2.60908C13.8895 2.25162 14.2048 1.9575 14.5773 1.75379C14.9498 1.55008 15.3676 1.44331 15.7922 1.44331C16.2167 1.44331 16.6345 1.55008 17.007 1.75379C17.3795 1.9575 17.6948 2.25162 17.9239 2.60908L29.1505 20.1262C29.3991 20.5076 29.5389 20.9495 29.5546 21.4045C29.5704 21.8594 29.4616 22.31 29.2399 22.7076Z"
        fill="#ED028C"
      />
    </svg>
  );
};

export { WarningIcon };
