const FilterHasValueIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 51 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="7.5" width="50" height="45" rx="3.5" fill="white" stroke="#2E3092" />
            <path d="M23.1235 24.6153L23.1235 24.9153L23.4235 24.9153L24.9288 24.9154L25.2288 24.9154L25.2288 24.6154L25.2288 23.4803L37 23.4803L37.3 23.4803L37.3 23.1803L37.3 21.4351L37.3 21.1351L37 21.1351L25.2288 21.1351L25.2288 20L25.2288 19.7L24.9288 19.7L23.4235 19.7L23.1235 19.7L23.1235 20L23.1235 24.6153ZM37 38.8649L37.3 38.8649L37.3 38.5649L37.3 36.8197L37.3 36.5197L37 36.5197L25.2288 36.5197L25.2288 35.3847L25.2288 35.0847L24.9288 35.0847L23.4235 35.0847L23.1235 35.0847L23.1235 35.3847L23.1235 40L23.1235 40.3L23.4235 40.3L24.9288 40.3L25.2288 40.3L25.2288 40L25.2288 38.8649L37 38.8649ZM21.7281 38.8649L22.0281 38.8649L22.0281 38.5649L22.0281 36.8197L22.0281 36.5197L21.7281 36.5197L14 36.5197L13.7 36.5197L13.7 36.8197L13.7 38.5649L13.7 38.8649L14 38.8649L21.7281 38.8649ZM37 31.1727L37.3 31.1727L37.3 30.8727L37.3 29.1275L37.3 28.8275L37 28.8275L28.2804 28.8275L28.2804 27.6924L28.2804 27.3924L27.9804 27.3924L26.4751 27.3924L26.1751 27.3924L26.1751 27.6924L26.1751 32.3078L26.1751 32.6078L26.4751 32.6078L27.9804 32.6078L28.2804 32.6078L28.2804 32.3078L28.2804 31.1727L37 31.1727ZM24.7797 31.1727L25.0797 31.1727L25.0797 30.8727L25.0797 29.1274L25.0797 28.8274L24.7797 28.8274L14 28.8274L13.7 28.8274L13.7 29.1274L13.7 30.8727L13.7 31.1727L14 31.1727L24.7797 31.1727ZM21.7281 23.4804L22.0281 23.4804L22.0281 23.1804L22.0281 21.4352L22.0281 21.1352L21.7281 21.1352L14 21.1352L13.7 21.1352L13.7 21.4352L13.7 23.1804L13.7 23.4804L14 23.4804L21.7281 23.4804Z" fill="#2E3092" stroke="#2E3092" stroke-width="0.6" />
            <rect x="29" width="14" height="14" rx="7" fill="#D92D20" />
        </svg>
    );
};

export { FilterHasValueIcon };