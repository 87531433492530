import {getSetApiData} from "@/services/api-service/get-set-api-data";
import {EHttpMethod} from "@/services/api-service/enums";
import {ICallAndSetData} from "@/services/api-service/interface";

const GET_ALL_PARAMETERS_URL = '/v1/printhouse-config/parameters/get-all-parameters';
const GET_PARAMETER_VALUES_BY_ID_URL = '/v1/printhouse-config/parameters/get-parameter-values-by-id';
const GET_ALL_OUTPUTS_URL = '/v1/printhouse-config/Output/get-all-Outputs';
const GET_ALL_FOLD_OPTION_URL = '/v1/printhouse-config/parameters/get-fold-options';


const getAllParameters: ICallAndSetData = async (callApi, setState) => {
    return  await getSetApiData(callApi, EHttpMethod.GET, GET_ALL_PARAMETERS_URL, setState);
};
const GetParameterValuesByIdAi: ICallAndSetData = async (callApi, setState,data) => {
    return  await getSetApiData(callApi, EHttpMethod.GET, GET_PARAMETER_VALUES_BY_ID_URL, setState,data);
};
const getAllOutputs: ICallAndSetData = async (callApi, setState) => {
    return  await getSetApiData(callApi, EHttpMethod.GET, GET_ALL_OUTPUTS_URL, setState);
};

const getAllFoldOptions: ICallAndSetData = async (callApi, setState) => {
    return  await getSetApiData(callApi, EHttpMethod.GET, GET_ALL_FOLD_OPTION_URL, setState);
};

export {getAllParameters, getAllOutputs,GetParameterValuesByIdAi,getAllFoldOptions};