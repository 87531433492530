const ProductionTrackingIcon = (props: any) => {
  return (

    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 12.9375H14.3438C14.7164 12.9364 15.0734 12.7879 15.3369 12.5244C15.6004 12.2609 15.7489 11.9039 15.75 11.5312V5.90625C15.7489 5.53363 15.6004 5.17659 15.3369 4.91311C15.0734 4.64963 14.7164 4.50111 14.3438 4.5H3.65625C3.28363 4.50111 2.92659 4.64963 2.66311 4.91311C2.39963 5.17659 2.25111 5.53363 2.25 5.90625V11.5312C2.25111 11.9039 2.39963 12.2609 2.66311 12.5244C2.92659 12.7879 3.28363 12.9364 3.65625 12.9375H4.5" stroke="#F2F0F6" stroke-linejoin="round" />
      <path d="M12.645 8.4375H5.355C4.8828 8.4375 4.5 8.8203 4.5 9.2925V14.895C4.5 15.3672 4.8828 15.75 5.355 15.75H12.645C13.1172 15.75 13.5 15.3672 13.5 14.895V9.2925C13.5 8.8203 13.1172 8.4375 12.645 8.4375Z" stroke="#F2F0F6" stroke-linejoin="round" />
      <path d="M13.5 4.5V3.65625C13.4989 3.28363 13.3504 2.92659 13.0869 2.66311C12.8234 2.39963 12.4664 2.25111 12.0937 2.25H5.90625C5.53363 2.25111 5.17659 2.39963 4.91311 2.66311C4.64963 2.92659 4.50111 3.28363 4.5 3.65625V4.5" stroke="#F2F0F6" stroke-linejoin="round" />
      <path d="M13.7812 7.3125C14.2472 7.3125 14.625 6.93474 14.625 6.46875C14.625 6.00276 14.2472 5.625 13.7812 5.625C13.3153 5.625 12.9375 6.00276 12.9375 6.46875C12.9375 6.93474 13.3153 7.3125 13.7812 7.3125Z" fill="#F2F0F6" />
    </svg>
  );
};

export { ProductionTrackingIcon };
