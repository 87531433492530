import { IIconProps } from "@/components/icons/interface";

export const UpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M10.5 8.75L7 5.25L3.5 8.75" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export const DownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M3.5 5.25L7 8.75L10.5 5.25" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
}

export const ProductIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.91406 5.83366H4.08073C5.2474 5.83366 5.83073 5.25033 5.83073 4.08366V2.91699C5.83073 1.75033 5.2474 1.16699 4.08073 1.16699H2.91406C1.7474 1.16699 1.16406 1.75033 1.16406 2.91699V4.08366C1.16406 5.25033 1.7474 5.83366 2.91406 5.83366Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.0807 1.16699H9.91406C8.7474 1.16699 8.16406 1.75033 8.16406 2.91699V4.08366C8.16406 5.25033 8.7474 5.83366 9.91406 5.83366H11.0807C12.2474 5.83366 12.8307 5.25033 12.8307 4.08366V2.91699" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.91406 12.8337H11.0807C12.2474 12.8337 12.8307 12.2503 12.8307 11.0837V9.91699C12.8307 8.75033 12.2474 8.16699 11.0807 8.16699H9.91406C8.7474 8.16699 8.16406 8.75033 8.16406 9.91699V11.0837C8.16406 12.2503 8.7474 12.8337 9.91406 12.8337Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.91406 12.8337H4.08073C5.2474 12.8337 5.83073 12.2503 5.83073 11.0837V9.91699C5.83073 8.75033 5.2474 8.16699 4.08073 8.16699H2.91406C1.7474 8.16699 1.16406 8.75033 1.16406 9.91699V11.0837" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

