const ReChooseIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8063_36772)">
        <path
          d="M8.36264 6.50256V7.30701C8.36264 7.5625 8.41367 7.77686 8.51438 7.94385C8.65329 8.17432 8.88132 8.3064 9.14023 8.3064C9.39389 8.3064 9.64597 8.18286 9.88938 7.93945L12.5464 5.28247C13.1297 4.6991 13.1297 3.74988 12.5464 3.1665L9.88938 0.509522C9.64597 0.265991 9.39389 0.142578 9.14023 0.142578C8.75315 0.142578 8.36264 0.45166 8.36264 1.14197V1.87256C4.59043 2.09534 1.46763 4.99023 1.00351 8.7804C0.977513 8.99207 1.09787 9.1947 1.29624 9.27319C1.35239 9.29541 1.41086 9.30615 1.4686 9.30615C1.61484 9.30615 1.75669 9.23767 1.84702 9.11426C3.09189 7.41333 5.09067 6.39783 7.1937 6.39783C7.58335 6.39783 7.97519 6.43298 8.36264 6.50256Z"
          fill="#ED028C"
        />
        <path
          d="M14.6954 7.01198C14.497 6.93337 14.2707 6.9988 14.1446 7.17104C12.8998 8.87185 10.901 9.88735 8.79802 9.88735C8.40837 9.88735 8.01653 9.85219 7.62907 9.78261V8.97817C7.62907 8.28786 7.23845 7.97878 6.85149 7.97878C6.59782 7.97878 6.34575 8.10231 6.10222 8.34584L3.44524 11.0027C2.86198 11.5861 2.86198 12.5353 3.44536 13.1188L6.10222 15.7757C6.34575 16.0192 6.59782 16.1426 6.85149 16.1426C7.23845 16.1426 7.62907 15.8335 7.62907 15.1432V14.4126C11.4014 14.1898 14.5241 11.2949 14.9882 7.5049C15.0142 7.29311 14.8938 7.09047 14.6954 7.01198Z"
          fill="#ED028C"
        />
      </g>
      <defs>
        <clipPath id="clip0_8063_36772">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.142578)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ReChooseIcon };
