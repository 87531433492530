const ExcelSheetIcon = (props: any) => {
  return (
    <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="40" rx="4" fill="#DCDCEC" />
      <path d="M8 16.2886L10.6731 18.9617V16.2886H8Z" fill="black" />
      <path d="M26.9611 3.92822H12.3302C11.8704 3.92822 11.4295 4.11086 11.1044 4.43596C10.7793 4.76106 10.5967 5.20198 10.5967 5.66174V32.5763C10.5967 33.0361 10.7793 33.477 11.1044 33.8021C11.4295 34.1272 11.8704 34.3098 12.3302 34.3098H32.3385C32.7982 34.3098 33.2391 34.1272 33.5642 33.8021C33.8893 33.477 34.072 33.0361 34.072 32.5763V11.0564L26.9611 3.92822Z" stroke="black" stroke-miterlimit="10" />
      <path d="M28.4631 11.0564H34.0867L26.9619 3.92822V9.55522C26.9619 9.95337 27.1201 10.3352 27.4016 10.6167C27.6831 10.8983 28.065 11.0564 28.4631 11.0564Z" fill="black" />
      <path d="M23.3971 16.2875H8V8.49365H23.3971C23.6567 8.49457 23.9054 8.59835 24.0886 8.78225C24.2719 8.96616 24.3748 9.2152 24.3748 9.47482V15.3064C24.3748 15.566 24.2719 15.815 24.0886 15.9989C23.9054 16.1829 23.6567 16.2866 23.3971 16.2875Z" fill="black" />
      <path d="M11.1143 14.1939L12.4248 12.1969L11.2391 10.3628H12.1405L12.9067 11.5936L13.6625 10.3628H14.557L13.3713 12.2211L14.6784 14.1939H13.7561L12.9032 12.8695L12.0504 14.1939H11.1143Z" fill="white" />
      <path d="M15.0732 14.1941V10.3804H15.836V13.5458H17.7567V14.1906L15.0732 14.1941Z" fill="white" />
      <path d="M18.1475 12.949L18.8998 12.8727C18.9055 12.9763 18.9318 13.0777 18.977 13.1711C19.0222 13.2644 19.0855 13.3479 19.1633 13.4166C19.241 13.4853 19.3316 13.5379 19.4298 13.5713C19.528 13.6047 19.6319 13.6183 19.7354 13.6112C20.2381 13.6112 20.4877 13.4344 20.4877 13.0842C20.493 13.0255 20.4823 12.9664 20.4566 12.9133C20.431 12.8602 20.3914 12.815 20.3421 12.7826C20.0781 12.6569 19.7999 12.5638 19.5135 12.5052C18.6953 12.2972 18.2861 11.9147 18.2861 11.3577C18.2785 11.2041 18.3097 11.0512 18.377 10.913C18.4443 10.7748 18.5454 10.6558 18.671 10.5672C18.97 10.3686 19.3249 10.2714 19.6834 10.2898C20.6368 10.2898 21.1291 10.6746 21.1534 11.4478L20.3802 11.4825C20.3721 11.4001 20.3475 11.3203 20.308 11.2476C20.2686 11.1748 20.2149 11.1108 20.1503 11.0591C20.0857 11.0074 20.0115 10.9692 19.9318 10.9466C19.8522 10.924 19.7689 10.9176 19.6868 10.9277C19.2534 10.9277 19.0385 11.0526 19.0385 11.3022C19.0402 11.3473 19.0513 11.3915 19.071 11.4321C19.0906 11.4726 19.1186 11.5087 19.1529 11.5379C19.3738 11.6694 19.6171 11.7587 19.8706 11.8014C20.5154 11.9679 20.9107 12.1481 21.0528 12.3527C21.196 12.5555 21.2724 12.7979 21.2713 13.0461C21.2713 13.8366 20.7604 14.2318 19.7388 14.2318C18.7785 14.263 18.2619 13.8227 18.1475 12.949Z" fill="white" />
      <path d="M28.2548 19.5337H16.5952C16.441 19.5341 16.2884 19.565 16.1462 19.6245C16.0039 19.6841 15.8749 19.7711 15.7663 19.8806C15.6578 19.9901 15.5719 20.12 15.5137 20.2627C15.4554 20.4055 15.4259 20.5583 15.4268 20.7125V29.9799C15.4259 30.291 15.5483 30.5898 15.7673 30.8107C15.9864 31.0317 16.2841 31.1568 16.5952 31.1587H28.2548C28.5662 31.1568 28.8643 31.0318 29.0839 30.811C29.3034 30.5901 29.4267 30.2913 29.4267 29.9799V20.7125C29.4271 20.5581 29.3972 20.4052 29.3385 20.2624C29.2799 20.1197 29.1937 19.9898 29.0849 19.8804C28.9761 19.7709 28.8467 19.684 28.7043 19.6245C28.5619 19.565 28.4092 19.5341 28.2548 19.5337ZM28.397 20.7125V23.0839H25.1275V20.5703H28.2479C28.2671 20.5694 28.2863 20.5724 28.3044 20.5791C28.3225 20.5858 28.339 20.5961 28.3529 20.6094C28.3668 20.6227 28.3779 20.6387 28.3855 20.6564C28.3931 20.6741 28.397 20.6932 28.397 20.7125ZM20.8423 26.6654V24.1795H24.1568V26.6654H20.8423ZM24.1568 27.761V30.1255H20.8423V27.761H24.1568ZM19.8785 26.6654H16.4565V24.1795H19.8785V26.6654ZM20.8423 23.0839V20.5703H24.1568V23.0839H20.8423ZM25.1275 24.1795H28.397V26.6654H25.1275V24.1795ZM16.5952 20.5703H19.8785V23.0839H16.4565V20.7125C16.456 20.694 16.4593 20.6756 16.466 20.6583C16.4728 20.6411 16.4829 20.6254 16.4959 20.6122C16.5088 20.5989 16.5242 20.5884 16.5413 20.5812C16.5583 20.574 16.5767 20.5703 16.5952 20.5703ZM16.4565 29.9799V27.761H19.8785V30.1255H16.5952C16.5765 30.125 16.5581 30.1209 16.541 30.1133C16.524 30.1058 16.5085 30.0949 16.4957 30.0814C16.4828 30.0679 16.4727 30.052 16.466 30.0345C16.4592 30.0171 16.456 29.9985 16.4565 29.9799ZM28.2444 30.122H25.1241V27.761H28.3935V29.9799C28.3935 30.017 28.379 30.0526 28.3531 30.0792C28.3272 30.1057 28.2919 30.1211 28.2548 30.122H28.2444Z" fill="black" />
      <path d="M31.9855 36.0712C34.2029 36.0712 36.0004 34.2737 36.0004 32.0563C36.0004 29.839 34.2029 28.0415 31.9855 28.0415C29.7682 28.0415 27.9707 29.839 27.9707 32.0563C27.9707 34.2737 29.7682 36.0712 31.9855 36.0712Z" fill="black" />
      <path d="M30.8243 30.0212V31.8345H29.8223C29.7939 31.8378 29.7668 31.849 29.7443 31.8667C29.7217 31.8844 29.7045 31.908 29.6944 31.9348C29.6844 31.9617 29.682 31.9908 29.6874 32.019C29.6928 32.0472 29.7059 32.0733 29.7253 32.0945L31.8991 34.4937C31.9117 34.5067 31.9268 34.517 31.9435 34.5241C31.9601 34.5311 31.9781 34.5348 31.9962 34.5348C32.0143 34.5348 32.0322 34.5311 32.0489 34.5241C32.0656 34.517 32.0806 34.5067 32.0932 34.4937L34.2497 32.1153C34.2694 32.0945 34.2827 32.0686 34.2883 32.0405C34.2939 32.0125 34.2915 31.9834 34.2814 31.9567C34.2713 31.9299 34.2539 31.9065 34.2311 31.8892C34.2083 31.8719 34.1812 31.8613 34.1527 31.8587H33.1126V30.0212C33.1135 30.0021 33.1106 29.9829 33.1042 29.9649C33.0977 29.9469 33.0877 29.9303 33.0749 29.9162C33.062 29.902 33.0464 29.8905 33.0291 29.8823C33.0118 29.8742 32.993 29.8695 32.9739 29.8687H30.9595C30.9215 29.8713 30.8861 29.8888 30.8608 29.9173C30.8355 29.9458 30.8224 29.9831 30.8243 30.0212Z" fill="white" />
    </svg>

  );
};

export { ExcelSheetIcon };
